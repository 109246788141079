import React from 'react'
import { connect } from 'react-redux'
import { Button as ButtonBS } from 'react-bootstrap'
import Spinner from '../../Spinner'
import { Form } from "react-bootstrap"
import { normalizeNumberSpaces } from '../../../utilities/normalize'
import SearchSelect from '../../SearchSelect'
import { loadRubrics, updateFilterParams } from '../../../store/Modal/types/PublicSongsFilter/actions'
import Tags from '../../Tags'
import Header from '../components/header'

import Uploader from '../../Uploader'
import InputText from '../../Form/InputText'
import InputTextarea from '../../Form/InputTextarea'
import Switch from '../../Form/Switch'
import Select from '../../Form/Select'
// import Checkbox from '../../Form?'


class PublicSongsFilter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: false,
            description: false,
            sumbitDisabled: true,
            checked: false,
            filter_params: {
                genre_name: [],
                songauthor_name: [],
                tempo: []
            }
        }
        this.hSubmit = this.hSubmit.bind(this)
        this.hSelect = this.hSelect.bind(this)
        this.hClear = this.hClear.bind(this)
        this.hDelete = this.hDelete.bind(this)
        this.hInputText = this.hInputText.bind(this)
        this.hCheck = this.hCheck.bind(this)
        this.validateForm = this.validateFrom.bind(this)
    }

    hInputText({ name, value }, event) {
        if (name && value) {
            this.setState(() => ({
                [name]: value
            }), this.validateFrom)
        }
    }
    hCheck(event) {
        this.setState({ checked: !this.state.checked })
    }
    hClear() {
        this.setState(() => ({
            filter_params: {
                genre_name: [],
                songauthor_name: [],
                tempo: []
            }
        }), this.validateFrom)
        this.props.updateFilterParams({})
    }
    hSubmit() {
        this.props.updateFilterParams(this.state.filter_params)
    }
    hSelect(data) {
        let filter = this.state.filter_params

        if (!filter[data.type]) {
            filter[data.type] = []
        }
        if (filter[data.type]?.indexOf(data.value) === -1) {
            filter[data.type].push(data.value)
        }
        this.setState({ filter_params: filter })
        this.validateFrom()
    }
    hDelete(data) {
        let filter = this.state.filter_params
        filter[data.type] = filter[data.type].filter(el => el !== data.value.name)
        this.setState({ filter_params: filter })
        this.validateFrom()
    }

    validateFrom() {
        if (this.state.filter_params.genre_name?.length > 0 || this.state.filter_params.songauthor_name?.length > 0 || this.state.filter_params.tempo?.length > 0) {
            this.setState({ sumbitDisabled: false })
        } else {
            this.setState({ sumbitDisabled: true })
        }
    }

    componentDidMount() {
        if (Object.values(this.props.filter_params).length > 0) {
            this.setState(() => ({ filter_params: { ...this.state.filter_params, ...this.props.filter_params } }), this.validateFrom)
        }
        if (this.props.rubrics === "" || this.props.selected_playlist !== this.props.rubrics_playlist) {
            this.props.loadRubrics()
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.rubrics !== this.props.rubrics && prevProps.rubrics !== "") {
            this.setState(() => ({
                filter_params: {
                    genre_name: [],
                    songauthor_name: [],
                    tempo: []
                }
            }), this.validateFrom)
            this.props.updateFilterParams({})
        }
    }

    render() {
        return (

            (!this.props.loading && this.props.rubrics !== "")
                ? < div className="ModalOneInput" >
                    <Header
                        title="Фильтр публичных треков"
                        toggleOpened={ this.props.toggleOpened }
                    />
                    <SearchSelect items={ this.props.rubrics.genre_name } title="Жанры" type="genre_name" placeholder="Введите название жанра..." params={ this.state.filter_params } hdelete={ this.hDelete } select={ this.hSelect } />
                    <div hidden style={ { marginTop: "20px" } }>
                        <SearchSelect items={ this.props.rubrics.songauthor_name } title="Авторы" type="songauthor_name" placeholder="Введите имя автора..." params={ this.state.filter_params } hdelete={ this.hDelete } select={ this.hSelect } />
                    </div>
                    <div style={ { marginTop: "20px" } }>
                        <SearchSelect items={ this.props.rubrics.tempo } title="Темп" placeholder="Введите скорость темпа..." type="tempo" params={ this.state.filter_params } hdelete={ this.hDelete } select={ this.hSelect } />
                    </div>

                    <div className="Modal__footer" style={ {
                        display: "flex",
                        justifyContent: "space-around"
                    } }>

                        <ButtonBS onClick={ this.hSubmit } variant="primary" disabled={ this.state.sumbitDisabled || this.props.loading }>
                            { this.props.loading ? "Поиск..." : "Применить" }
                        </ButtonBS>


                        <ButtonBS onClick={ this.hClear } variant="dark" disabled={ this.state.sumbitDisabled || this.props.loading }>
                            Сбросить
                        </ButtonBS>


                    </div>
                </div >
                : <div style={ { display: "flex", flexDirection: "column" } }>
                    <Spinner />
                    <span style={ { textAlign: "center", marginTop: "10px" } }><h5>Загружаем параметры фильтра...</h5></span>
                </div>
        )
    }
}

const stateToProps = state => ({
    loading: state.modalPublicSongsFilter.loading,
    rubrics: state.modalPublicSongsFilter.rubrics,
    rubrics_playlist: state.modalPublicSongsFilter.playlist,
    selected_playlist: state.public_playlists.selected[0],
    filter_params: state.modalPublicSongsFilter.filter_params
})

const actionsToProps = {
    loadRubrics,
    updateFilterParams
}



export default connect(stateToProps, actionsToProps)(PublicSongsFilter)