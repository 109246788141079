import React from 'react'


const WarningRed = ({ title = '', className = '', color = '#E34850', mSize = 18, size, variant, rotate = false, onClick }) => {
    return (
        <i className={`${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}`} onClick={onClick}>
            <svg width={size / 18 * 22} height={size} viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>{title}</title>
                <path d="M20.266 17.9981H1.73301C1.37575 17.9981 1.04563 17.8075 0.867 17.4981C0.688374 17.1887 0.688376 16.8075 0.867007 16.4981L10.133 0.498114C10.3118 0.189226 10.6416 -0.000976562 10.9985 -0.000976562C11.3554 -0.000976562 11.6852 0.189226 11.864 0.498114L21.13 16.4981C21.3085 16.8074 21.3086 17.1883 21.1303 17.4977C20.9519 17.807 20.6221 17.9977 20.265 17.9981H20.266ZM11 2.99811L3.46901 15.9981H18.533L11 2.99811ZM11.995 11.9991H9.99501V6.99811H11.995V11.9991Z" fill={color} />
                <path d="M10 13.0001H12V15.0001H10V13.0001Z" fill={color} />
            </svg>
        </i>
    )
}

export default WarningRed
