import React from 'react'
import { connect } from 'react-redux'

import { openModal, closeModal } from '../../../../../store/Modal/actions'
import { deleteTracks, downloadTracks } from '../../../../../store/Tracks/actions'

import Collapsible from '../../../../../components/Space/Collapsible'
import Button from '../../../../../components/Button'
import Tracks from '../../../../../components/Tracks'

import SvgFileM from '../../../../../components/SVG/FileM'
import SvgLog from '../../../../../components/SVG/Log'
import Spinner from '../../../../../components/Spinner'


const Video = ({
    className = "",
    active,
    placeNumber,
    openModal,
    closeModal,
    selectedTracks = [],
    deleteTracks,
    title,
    downloadTracks,
    toggleHideEnded,
    loading
}) => {

    //let title = 'Ролики'
    //if (selectedTracks.length > 1) title += ` {<span>${selectedTracks.length}</span>}`
    return (
        <>
            <Collapsible
                active={active}
                id={111}
                className={`CollapsibleVideo ${className}`}
                title={<>{title} {selectedTracks.length > 1 ? <small className='badge badge-light'>{selectedTracks.length}</small> : ''}</>}
                placeNumber={placeNumber}
                elements={
                    <>
                        {/* <Button
                            type="string"
                            disabled={ (selectedTracks[0] && !selectedTracks[1]) ? false : true }
                            onClick={ () => openModal({
                                type: "videoHistory",
                                size: "md"
                            }) }
                        >
                            <SvgLog/>
                        </Button> */}
                        <Button
                            type="string"
                            disabled={(selectedTracks[0] && !selectedTracks[1]) ? false : true}
                            onClick={() => openModal({
                                type: "mediaplan",
                            })}
                        >
                            <SvgFileM /> Медиаплан
                        </Button>
                    </>
                }
                buttons={{
                    add: {
                        on: true,
                        title: "Добавить ролик",
                        onClick: () => openModal({
                            type: "adsCreate",
                        })
                    },
                    download: {
                        on: true,
                        disabled: (selectedTracks[0]) ? false : true,
                        onClick: () => downloadTracks({ tracksIds: selectedTracks })
                    },
                    edit: {
                        on: true,
                        disabled: (selectedTracks[0] && !selectedTracks[1]) ? false : true,
                        onClick: () => openModal({
                            type: "adsEdit",
                        })
                    },
                    remove: {
                        on: true,
                        disabled: (selectedTracks[0]) ? false : true,
                        onClick: () => openModal({
                            type: "confirm",
                            size: "sm",
                            data: {
                                title: "Вы точно хотите удалить выделенные ролики?",
                                buttons: {
                                    cancel: {
                                        title: "Вернуться",
                                        onClick: () => {
                                            closeModal("confirm")
                                        }
                                    },
                                    confirm: {
                                        title: "Удалить",
                                        onClick: () => {
                                            deleteTracks(selectedTracks)
                                            closeModal("confirm")
                                        }
                                    }
                                }
                            }
                        })
                    }
                }}
            >
                        {loading ? <Spinner className="pt-xs pb-xs" /> :   <Tracks toggleHideEnded={toggleHideEnded} />}

               
            </Collapsible>
        </>
    )
}

const stateToProps = state => {
    const ids = state.tracks.tracks.map(tr => tr.id)
    return {
        loading: state.objects.loading,
        selectedTracks: state.tracks.selected.filter(id => ids.includes(id))
    }
}

const actionsToProps = {
    openModal, closeModal, deleteTracks, downloadTracks
}

export default connect(stateToProps, actionsToProps)(Video)