import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import store from '../../store/reducer';


//Вспомогательный компонент для определения того, какие компоненты можно показывать не авторизованному пользователю

export const PrivateRoute = ({ component: Component, ...rest }) => {

    
    const note = useSelector(state => state.features.note);

    useEffect(() => {
        document.title =  !!note?.length ? `MS BOX - ${note}` : "MS BOX";        
        
    }, [note]);

    return (
        <Route {...rest} render={props => (

            localStorage.getItem('sessionToken')
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )} />
    )
};

