import React from 'react'
import { connect } from 'react-redux'

import Songs from './Songs'


const SongsContainer = props => {

    return (
        <Songs
            id={props.id}
            name={props.name}
            songs={props.songs}
            selected={props.selected_song}
            markItems={props.markItems}
            tags={props.tags}
            turn={props.turn}
            cliptime={props.cliptime}
            tempo={props.tempo}
            songauthor_name={props.songauthor_name}
            hide_song_author_name={props.hide_song_author_name}

            player_name={props.player_name}
            album_name={props.album_name}
            genre_name={props.genre_name}
            year={props.year}
            filename={props.filename}
            variant={props.variant}

            audio={props.audio}
            trackPlaying={props.trackPlaying}
            isPlaying={props.isPlaying}
            isWatching={props.isWatching}
            play={props.play}
            pause={props.pause}
            stop={props.stop}
            rewind={props.rewind}

        />
    )
}

const stateToProps = state => ({
    markItems: [...state.marks[state.marks.current].items],
    selected: [...state.playlists.selected],
    selected_song: [...state.playlists.selected_song]
})

export default connect(stateToProps, null)(SongsContainer)