import React from 'react'


const Menu = ({ title = 'Меню', className = '', size, variant, rotate = false, onClick }) => {
    return (
        <i className={ `${className} spp-svg ${variant ? 'spp-svg-color-' + variant : ''} ${size ? 'spp-svg-size-' + size : ''} ${rotate === true ? 'rotate' : ''}` } onClick={ onClick }>
            <svg xmlns="http://www.w3.org/2000/svg">
                <title>{ title }</title>
                <defs><clipPath id="a"><path fill="none" d="M0 0h30v24.571H0z" /></clipPath></defs><g data-name="Symbol 1 – 1" clip-path="url(#a)" transform="scale(.65117)" strokeWidth="1.536"><path d="M.5 0a.5.5 0 100 1.002h29A.5.5 0 1029.5 0H.5zm0 11.785a.5.5 0 100 1.001h29a.5.5 0 100-1.001H.5zm0 11.787a.5.5 0 100 1.002h29a.5.5 0 100-1.002H.5z" data-name="Group 296" fill="#0a0a0a" /></g></svg>
        </i>
    )
}

export default Menu