import React from 'react'
import moment from 'moment'

import { cl } from '../../../utilities/cl'
import { createCalendar } from '../utilities/createCalendar'
import { createMonthsList } from '../utilities/createMonthsList'
import { createYearsList } from '../utilities/createYearsList'
import { getDatesBetween } from '../utilities/getDatesBetween'
import { highlightCalendar } from '../utilities/highlightCalendar'
import { compareObjects } from '../../../utilities/compare'
import { normalizeDate } from '../../../utilities/normalize'
import { russifyDate } from '../../../utilities/russifyDate'

import Select from '../Select'


const data = {
    oneDate: false,

    disabledType: "past", // "future"
    disabledDates: ["2020-07-15", "2020-07-20"],
    disabledPeriod: {
        from: "2020-07-15",
        to: "2020-07-20",
    },

    // default: "2020-07-15",
    default: {
        from: "2020-07-15",
        to: "2020-07-20",
    },
}


class Single extends React.PureComponent {
    constructor(props) {
        super(props)
        this.refPicker = React.createRef()

        this.getNewState = this.getNewState.bind(this)

        this.state = this.getNewState(this.props)

        this.updateState = this.updateState.bind(this)
        this.hSelectDate = this.hSelectDate.bind(this)
        this.hSelectMonth = this.hSelectMonth.bind(this)
        this.hSelectYear = this.hSelectYear.bind(this)
        this.setSelected = this.setSelected.bind(this)
    }

    componentDidMount() {
        this.updateState({
            nextProps: this.props
        })
    }

    componentDidUpdate(prevProps) {
        let nextProps = this.props

        if (!compareObjects({
            prev: prevProps,
            next: nextProps
        })) {
            this.updateState({
                nextProps: this.props
            })
        }

        // if(arraysHasDifferences(prevProps, nextProps)) {
        //     this.updateState({
        //         nextProps: this.props
        //     })
        // }
    }

    updateState({ nextProps, newParams }, empty) {
        if (newParams && nextProps === empty) {
            newParams = {
                ...this.state,
                ...newParams
            }
        }
        else if (nextProps && newParams === empty) {
            newParams = {
                ...nextProps
            }
        }
        this.setState(
            this.getNewState(newParams),
            () => {
                if (this.props.noHighlight !== true) {
                    highlightCalendar({
                        picker: this.refPicker.current,
                        selected: this.state.selected,
                        disabled: this.state.disabled,
                    })
                }
                if (typeof this.props.insideOnChange === "function") {
                    this.props.insideOnChange(this.state)
                }
                if (typeof this.props.insideHandleСhangeDisabledDates === "function") {
                    this.props.insideHandleСhangeDisabledDates(this.state.disabled)
                }
                if (typeof this.props.onChange === "function") {
                    const selected = this.state.selected
                    let answerDates = {}

                    if (selected.from && selected.from !== null && selected.to && selected.to !== null) {
                        answerDates = {
                            from: selected.to,
                            to: selected.from,
                        }
                    } else {
                        if (selected.from === null) {
                            answerDates = {
                                from: "",
                                to: "",
                            }
                        } else {
                            answerDates = {
                                from: selected.from,
                                to: "",
                            }
                        }
                    }

                    if (answerDates.from.length && answerDates.to.length) {
                        let dates = [answerDates.from, answerDates.to]
                        dates = dates.map(date => Number(moment(date, 'YYYY-MM-DD').format("x")))
                        dates.sort()
                        answerDates = {
                            from: dates[0],
                            to: dates[1],
                        }
                    }

                    if (typeof this.props.format === "string") {
                        if (answerDates.from.length || typeof answerDates.from === "number") answerDates.from = moment(answerDates.from, 'YYYY-MM-DD').format(this.props.format)
                        if (answerDates.to.length || typeof answerDates.to === "number") answerDates.to = moment(answerDates.to, 'YYYY-MM-DD').format(this.props.format)
                    } else {
                        if (answerDates.from.length || typeof answerDates.from === "number") answerDates.from = moment(answerDates.from, 'YYYY-MM-DD').format("DD[.]MM[.]YYYY")
                        if (answerDates.to.length || typeof answerDates.to === "number") answerDates.to = moment(answerDates.to, 'YYYY-MM-DD').format("DD[.]MM[.]YYYY")
                    }

                    if (this.props.oneDate === true) {
                        this.props.onChange(answerDates.from)
                    } else {
                        this.props.onChange({ ...answerDates })
                    }
                }
            })
    }

    getNewState(newParams) {
        let nextState = {},
            disabled = this.state?.disabled || [],
            toDisplayDate,
            defaultSelected = false

        if (typeof newParams.default === "string" || (typeof newParams.default === "object" && newParams.default !== null && !Array.isArray(newParams.default))) {
            if (typeof newParams.default === "string") {
                defaultSelected = {
                    from: normalizeDate(newParams.default),
                    to: null
                }
            } else {
                defaultSelected = {}
                defaultSelected.from = normalizeDate(newParams.default.from)
                defaultSelected.to =
                    newParams.default.to && newParams.default.to !== null
                        ? normalizeDate(newParams.default.to)
                        : null
            }

            if (this.props.oneDate === true) {
                defaultSelected.to = null
            }
            toDisplayDate = defaultSelected.from
        }

        if (newParams.year && newParams.month && newParams.day) {
            toDisplayDate = `${newParams.year}-${newParams.month}-${newParams.day}`
        }

        if (newParams.clicked) {
            toDisplayDate = newParams.clicked
        }

        const today = normalizeDate((toDisplayDate || new Date()))
        const day = Number(moment(today, 'YYYY-MM-DD').format("D"))
        const month = Number(moment(today, 'YYYY-MM-DD').format("M"))
        const year = Number(moment(today, 'YYYY-MM-DD').format("YYYY"))
        const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM-DD').daysInMonth()
        const startDay = moment(`${year}-${month}`, 'YYYY-MM-DD').date(1).day()
        const dateTitle = moment(`${year}-${month}`, 'YYYY-MM-DD').lang("ru").format("MMMM Y")

        nextState.day = day
        nextState.month = month
        nextState.year = year
        nextState.daysInMonth = daysInMonth
        nextState.startDay = startDay
        nextState.dateTitle = dateTitle

        // Списки Месяц, Год

        nextState.monthsList = createMonthsList({
            month: month,
            year: year,
            selected: month
            // disabledType: newParams.disabledType,
        })

        nextState.yearsList = createYearsList({
            minus: 3,
            plus: 3,
            year: year,
            // disabledType: newParams.disabledType
        })

        // Блокировка дат

        if (newParams.disabledType === "past") {
            let currentYear = Number(moment(new Date(), 'YYYY-MM-DD').format("YYYY")),
                currentMonth = Number(moment(new Date(), 'YYYY-MM-DD').format("M")),
                currentDay = Number(moment(new Date(), 'YYYY-MM-DD').format("D"))

            if (year < currentYear) {
                let i = 1
                while (i < 35) {
                    disabled.push(`${year}-${month}-${i++}`)
                }
            } else if (year === currentYear) {
                let i = -100
                if (month < currentMonth) i = 35
                if (month === currentMonth) i = (currentDay - 1)

                while (i > 0) {
                    disabled.push(`${year}-${month}-${i--}`)
                }
            }
        }

        if (newParams.disabledType === "future") {
            let currentYear = Number(moment(new Date(), 'YYYY-MM-DD').format("YYYY")),
                currentMonth = Number(moment(new Date(), 'YYYY-MM-DD').format("M")),
                currentDay = Number(moment(new Date(), 'YYYY-MM-DD').format("D"))

            if (year > currentYear) {
                let i = 1
                while (i < 35) {
                    disabled.push(`${year}-${month}-${i++}`)
                }
            } else if (year === currentYear) {
                let i = 100
                if (month > currentMonth) i = 1
                if (month === currentMonth) i = (currentDay + 1)

                while (i < 35) {
                    disabled.push(`${year}-${month}-${i++}`)
                }
            }
        }

        if (newParams.disabledPeriod || newParams.disabledDates) {
            if (Array.isArray(newParams.disabledDates)) {
                disabled = disabled.concat(newParams.disabledDates)
            }

            if ((newParams.disabledPeriod?.from && newParams.disabledPeriod?.to)
                && (newParams.disabledPeriod.from !== null && newParams.disabledPeriod.to !== null)
            ) {
                disabled = disabled.concat(getDatesBetween([newParams.disabledPeriod.from, newParams.disabledPeriod.to]))
                disabled.push(newParams.disabledPeriod.from)
                disabled.push(newParams.disabledPeriod.to)
            }
        }

        // Устанавливаем настройки

        nextState.disabled = normalizeDate([...new Set(disabled)])
        nextState.disabledType = newParams.disabledType
        nextState.selected = defaultSelected || newParams.selected || { from: null, to: null }
        return nextState
    }

    hSelectDate(event) {
        if (event.target.tagName === "SPAN") {
            if (event.target.innerHTML.length > 0) {
                const date = event.target.dataset.date

                if (Array.isArray(this.state.disabled)) {
                    if (this.state.disabled.indexOf(date) === -1) {

                        this.setSelected(date)

                        if (typeof this.props.insideHandleChangeSelected === "function") {
                            this.props.insideHandleChangeSelected(date)
                        }
                    }
                }
            }
        }
    }
    hSelectMonth(selected) {
        console.log(' hSelectMonth', selected, this.state);


        
            const { year, month, day } = this.state;
            console.log({ year, month, day });
            const daysInMonth = moment(`${year}-${month}-1`).daysInMonth()
            console.log({ daysInMonth });
        this.updateState({
            newParams: {
                month: Number(selected.value),
                day: daysInMonth < day ? daysInMonth : day
            }

        })
        if (typeof this.props.insideHandleChangeDates === "function") {
            this.props.insideHandleChangeDates({
                month: Number(selected.value),
                year: this.state.year,
                day: daysInMonth < day ? daysInMonth : day
            })
            console.log(' insideHandleChangeDates', selected.value);

        }
    }
    hSelectYear(selected) {
        this.updateState({
            newParams: {
                year: Number(selected.value)
            }
        })
        if (typeof this.props.insideHandleChangeDates === "function") {
            this.props.insideHandleChangeDates({
                year: Number(selected.value),
                month: this.state.month,
                day: this.state.day
            })
        }
    }

    render() {
        let {
            className = "",
            title
        } = this.props
        let {
            monthsList,
            yearsList,
            dateTitle,
            startDay,
            daysInMonth,
            year,
            month,
        } = this.state

        className = cl(
            "FormDatepickerSingle",
            className,
        )

        return (
            <div className={className} ref={this.refPicker}>
                <div className="FormDatepickerSingle__title">
                    {title}
                </div>
                <div className="FormDatepickerSingle__selects">
                    <Select
                        type="options"
                        placeholder="Месяц"
                        onChange={this.hSelectMonth}
                        selected={month}
                        options={monthsList}
                    />
                    <Select
                        type="options"
                        placeholder="Год"
                        selected={year}
                        onChange={this.hSelectYear}
                        options={yearsList}
                    />
                </div>

                <div className="FormDatepickerSingle__dates">
                    <div className="days">
                        <div className="title">{russifyDate(dateTitle)}</div>
                        <div className="FormDatepickerSingle__row">
                            <span>Пн</span>
                            <span>Вт</span>
                            <span>Ср</span>
                            <span>Чт</span>
                            <span>Пт</span>
                            <span>Сб</span>
                            <span>Вс</span>
                        </div>
                    </div>
                    <div className="dates" onClick={this.hSelectDate}>
                        {createCalendar({
                            startDay: startDay,
                            daysInMonth: daysInMonth,
                            year: year,
                            month: month,
                        })}
                    </div>
                </div>
            </div>
        )
    }

    setSelected(date) {
        console.log('setSelected(date) {', date);
        date = normalizeDate(date)
        let selected = this.state.selected || { from: null, to: null }

        if (this.props.oneDate === true) {
            selected.from = (selected.from === date) ? null : date
            selected.to = null

        } else {
            let dates = []
            if (selected.from !== null) dates.push(selected.from)
            if (selected.to !== null) dates.push(selected.to)

            if (dates.indexOf(date) !== -1) {
                dates.splice(dates.indexOf(date), 1)
            } else {
                dates.length > 0
                    ? dates[1] = date
                    : dates[0] = date
            }

            dates = dates.map(date => Number(moment(date).format("x")))
            dates.sort()

            selected.from = dates[0] ? normalizeDate(moment(dates[0], 'YYYY-MM-DD').format("YYYY-M-D")) : null
            selected.to = dates[1] ? normalizeDate(moment(dates[1], 'YYYY-MM-DD').format("YYYY-M-D")) : null
        }

        this.updateState({
            newParams: {
                selected: selected,
                clicked: date
            }
        })
    }
}

export default Single