import React, {useState} from "react";
import Datepicker from "../../../../components/Form/Datepicker";
import Settings from "../../../../components/Settings";
import {MAIN_OBJECT} from "../../../routes";
import {cl} from "../../../../utilities/cl";
import {buildGETUrl} from "../../../../utilities/buildGETUrl";

const ReportDownload = ({
                            className = "",
                            selectedObjects
                        }) => {

    const [period, setPeriod] = useState({from: '', to: ''})
    const hChange = ({from, to}) => {
        setPeriod({from, to})
    }

    return (
        <div>
            <Datepicker
                className="mb-3"
                type={"between"}
                durationInput={true}
                variant="icon"
                titles={{
                    main: "Период",
                    from: "Дата начала",
                    to: "Дата оконча2ния",
                }}
                data={{
                    //disabledType: "past",
                }}
                onChange={hChange}
            />
            <a href={ buildGETUrl('docs/report/music', {ms_id: selectedObjects, from: period.from, to: period.to}) } className={ cl("nav-link d-block", {disabled: !period.from || !period.to}) }>
                Отчет трансляции музыки
            </a>
            <a href={ buildGETUrl('docs/report/rao', {ms_id: selectedObjects, from: period.from, to: period.to}) } className={ cl("nav-link d-block", {disabled: !period.from || !period.to}) }>
                Отчет для РАО
            </a>
            <a href={ buildGETUrl('docs/report/vois', {ms_id: selectedObjects, from: period.from, to: period.to}) } className={ cl("nav-link d-block", {disabled: !period.from || !period.to}) }>
                Отчет для ВОИС
            </a>
            <a href={ buildGETUrl('docs/report/cliplog', {ms_id: selectedObjects, from: period.from, to: period.to}) } className={ cl("nav-link d-block", {disabled: !period.from || !period.to}) }>
                Отчет по рекламе
            </a>
        </div>

    )
}

export default ReportDownload