import moment from 'moment'
import { takeEvery, put, call, select } from 'redux-saga/effects'
import { buildGETUrl } from '../../../utilities/buildGETUrl'
import { COPY_PERIOD, loadPeriods, saving } from '../../../store/Settings/actions/components/Periods'
import { closeModal } from '../../../store/Modal/actions'
import { showAlertError, showAlertSuccess } from '../../../store/Alert/actions'
import axios from 'axios'
import { loadItems as loadBackgroundItems } from '../../../store/Settings/actions/sets/Object/Background'
import { loadItems as loadScheduleItems } from '../../../store/Settings/actions/sets/Object/Schedule'


export default function* () {
    yield takeEvery(COPY_PERIOD, worker)
}

function* worker({ payload }) {
    const { type, id } = payload


    yield put(saving({ type, saving: true }))

    try {
        const object_id = yield select(state => state.objects.selected)

        const objectsGroup_id = yield select(state => state.objectsGroup.selected)

        console.log({ object_id, objectsGroup_id });

        // const objectOpened = yield select(state => state.openedCollapsable.objects)
        // const objectsGroupOpened = yield select(state => state.openedCollapsable.objectsGroup)

        const ids = !!objectsGroup_id.length ? objectsGroup_id : object_id;

        const copied = yield call(fetchCopy, type, id, ids)

        // Ошибка
        if (copied.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${COPY_PERIOD}.`, copied],
                text: "Период не был скопирован. Попробуйте повторить."
            }))

            // Успех
        } else {

            // yield put(loadPeriods({type, required: true}))
            yield put(loadBackgroundItems({
                stateKey: ids.join(','),
                required: true,
            }))
            yield put(loadScheduleItems({
                stateKey: ids[0],
                required: true,
            }))

            yield put(closeModal("element"))

            yield put(showAlertSuccess({
                text: copied.warning || "Период скопирован.",
            }))
        }

    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${COPY_PERIOD}.`, e],
            text: "Период не был скопирован. Попробуйте повторить."
        }))
    } finally {
        yield put(saving({ type, saving: false }))
    }
}

function fetchCopy(type, id, object_id) {
    let formData = new FormData()
    formData.set("to_date_id", id)
    formData.set("ms_id", object_id)

    return axios(
        {
            url: `/msbox/${type}/dates/copy`,
            method: "POST",
            data: formData,
        }
    ).then(response => response.data)
}