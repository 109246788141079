import { takeEvery, call, put, select } from 'redux-saga/effects'
import { loadTracks } from '../../store/Tracks/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'
import { CLEAR_ADVERT } from "../../store/AdvertClear/actions";
import { loadIndentObjects } from "../../store/Objects/actions";
import { loadClips } from "../../store/Settings/actions/components/Clips";

export default function* () {
    yield takeEvery(CLEAR_ADVERT, worker)
}

function* worker({ payload }) {
    
    

    //смотрим хотим ли мы удалить рекламу с конкретных объектов или целиком
    const { selectedObjects, selectedTracks } = payload

    try {
        const selectedTracks = yield select(state => state.tracks.selected)
        //

        let formData = new FormData()

        let data = {};
        if(!!payload.length){
                formData.set('ms_id', payload);
        }
        if(!!selectedTracks.length){
            formData.set('indent_id', selectedTracks);
        }


        const response = yield call(fetchClear, formData)

        if (response.error) {
            yield put(showAlertError({
                errors: [`Ошибка сервера при ${CLEAR_ADVERT}.`, response],
                text: "Реклама не удалена. Попробуйте повторить."
            }))

        } else {
            yield put(loadTracks())
            yield put(loadTracks({ theme: true }))
            yield put(loadIndentObjects())
            //
            yield put(loadClips({
                indentId: selectedTracks[0],
                objectIds: payload,
                required: true
            }))
        }
    } catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${CLEAR_ADVERT}.`, e],
            text: "Реклама не удалена. Попробуйте повторить."
        }))
    } finally {
        // yield put(loading(false))
    }
}

function fetchClear(formData) {

    return axios(
        {
            url: "/timeline/clip/delete",
            method: "POST",
            data: formData
        }
    )
        .then(response => response.data)
}