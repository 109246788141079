import { takeEvery, call, put } from 'redux-saga/effects'
import { buildGETUrl } from '../../utilities/buildGETUrl'
import { loading, passRecovery, PASS_RECOVERY, putRecovery } from '../../store/Login/actions'
import { showAlertError } from '../../store/Alert/actions'
import axios from 'axios'

export default function* () {
    yield takeEvery(PASS_RECOVERY, worker)
}

function* worker({ payload }) {
    try {
        yield put(loading(true))
        const auth = yield call(fetchRecovery, payload)
        if (auth.error) {
            // yield put(showAlertError({
            //     errors: [`Ошибка сервера при ${DO_LOGIN}.`, auth],
            //     text: "Авторизация не пройдена. Попробуйте заново."
            // }))
            yield put(putRecovery(auth.error))
            yield put(loading(false))
        } else {
            yield put(putRecovery('good'))
            yield put(loading(false))
        }
    }
    catch (e) {
        yield put(showAlertError({
            errors: [`Ошибка сети при ${PASS_RECOVERY}.`, e],
            text: "Авторизация не пройдена. Попробуйте заново."
        }))
    }
}

function fetchRecovery(addData) {
    let formData = new FormData()
    if (addData && addData.email) {
        Object.keys(addData).forEach(name => {
            formData.set(name, addData[name])
        })
    }
    return axios(
        {
            method: "POST",
            url: "/account/password-reset",
            data: formData,
            // header: 'Access-Control-Allow-Origin: http://localhost:3000',
        })
        .then(response => response.data)
}