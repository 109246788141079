import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { cl } from '../../../../utilities/cl'

import Place from '../../../../components/Space/Place'
import Empty from '../../../../components/Space/Empty'

import SlideMenu from '../../../../components/SlideMenu'
import ObjectsMenu from '../../../../components/ObjectsMenu'

import Objects from './Objects'
import ObjectsGroup from './ObjectsGroup'


import { changeCollapsibleValue } from '../../../../store/OpenedCollapsable/actions'
import { toggleSelectedObject } from '../../../../store/Objects/actions'
import { toggleSelectedObjectState } from '../../../../store/Reports/actions'
import { toggleSelectedGroup } from '../../../../store/ObjectsGroup/actions'
import { updateSelectedByGroupsAndTags } from '../../../../store/Objects/actions'

class What extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            empty: false
        }

        this.setEmptyMode = this.setEmptyMode.bind(this)
    }


    componentDidMount() {
        this.props.changeCollapsibleValue({ name: "objects", value: true })
    }

    componentDidUpdate() {
        const {
            searchActive,
            searchFounded,
            searchValue,
        } = this.props

        if (searchActive) {
            (searchValue === "")
                ? this.setEmptyMode(false)
                : this.setEmptyMode(searchFounded.length === 0)
        } else {
            this.setEmptyMode(false)
        }
    }

    setEmptyMode(mode) {
        if (!mode) {
            this.setState(state => {
                if (state.empty) {
                    return { empty: false }
                }
            })
        } else {
            this.setState(state => {
                if (!state.empty) {
                    return { empty: true }
                }
            })
        }
    }

    render() {
        const {
            searchActive,
            changeCollapsibleValue,

            toggleSelectedObject,
            toggleSelectedObjectState,

            toggleSelectedGroup,
            updateSelectedByGroupsAndTags
        } = this.props
        const {
            empty
        } = this.state

        const hideContent = cl({ "d-none": empty })
        const placeHolder = cl({ "d-none": !empty })

        const clearSelected = () => {

            try {

                const objectPayload = {
                    selected: [],
                    simple: true
                }
                toggleSelectedObject(objectPayload)
                toggleSelectedObjectState(objectPayload)


                toggleSelectedGroup(objectPayload)


                updateSelectedByGroupsAndTags({
                    type: 'groups',
                    selected: [],
                    id: null
                })



            } catch (clearSelectedError) {
                console.log({ clearSelectedError });
            }
        }

        return (
            <>
                {/* 1 */}

                <Place
                    number={1}
                    className={cl(
                        "ObjectWhat",
                        { "search-active": searchActive }
                    )}
                >
                    <ObjectsMenu />

                    <SlideMenu
                        activeItem={1}
                        className={hideContent}
                        items={[
                            {
                                title: "Объекты",
                                content: <Objects className={hideContent} placeNumber={1} active
                                    onChangeCollapsible={(value) => {
                                        changeCollapsibleValue({ name: "objects", value })
                                        clearSelected()
                                    }}

                                />
                            },
                            {
                                title: "Группы объектов",
                                content: <ObjectsGroup className={hideContent} placeNumber={1}
                                    onChangeCollapsible={(value) => {
                                        changeCollapsibleValue({ name: "objectsGroup", value })
                                        clearSelected()
                                    }}

                                />
                            }
                        ]}
                    />

                    <Empty className={placeHolder}>
                        Объектов с таким названием не найдено
                    </Empty>
                </Place>
            </>
        )
    }
}

const actionsToProps = {
    changeCollapsibleValue,

    toggleSelectedObject,
    toggleSelectedObjectState,

    toggleSelectedGroup,
    updateSelectedByGroupsAndTags
}

const stateToProps = state => ({
    searchActive: state.search["objects"].modeActive,
    searchValue: state.search["objects"].value,
    searchFounded: [...state.search["objects"].founded],
})

export default connect(stateToProps, actionsToProps)(What)