import React, {useState} from 'react'
import {connect} from 'react-redux'
import {Button as ButtonBS} from 'react-bootstrap'

import {saveItem} from '../../../../../../store/Settings/actions/sets/Object/Silent'
import {OBJECT_SILENT} from '../../../../../../store/Settings/sets'

import Datepicker from '../../../../../Form/Datepicker'


const ModalContent = ({
    stateKey,
    itemId,
    saving,
    saveItem,
}) => {

    const [dates, setDates] = useState({})

    const hSave = () => saveItem({
        stateKey,
        itemId,
        settings: {
            fromdate: dates.from,
            todate: dates.to
        }
    })

    return(
        <>
            <Datepicker
                type="between"
                durationInput={true}
                variant="icon"
                onChange={setDates}
                titles={{
                    main: "Выберите даты",
                    from: "Дата начала",
                    to: "Дата окончани1я"
                }}
                data={{
                    disabledType: "past",
                }}
            />

            <div className="Modal__footer">
                <ButtonBS variant="prim" onClick={hSave} disabled={saving}>
                    {saving ? "Сохранение..." : "Сохранить"}
                </ButtonBS>
            </div>
        </>
    )
}

const stateToProps = (state, props) => {
    const store  = state.settings.settings[OBJECT_SILENT]
    const data   = store[props.stateKey] || {}
    const saving = data.__saving
    return {
        saving
    }
}

const actionsToProps = {
    saveItem
}

const ModalContentContainer = connect(stateToProps, actionsToProps)(ModalContent)


const editItem = ({
    stateKey,
    itemId,
    openModal,
}) => {

    openModal({
        type: "element",
        size: "sm",
        data: {
            title: "Редактирование расписания",
            element:
                <ModalContentContainer
                    stateKey={stateKey}
                    itemId={itemId}
                />
        }
    })
}

export default editItem